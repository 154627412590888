







import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class TermsOfUse extends Vue {
  terms = "tset";
  async mounted() {
    this.terms = await fetch("/terms.txt").then((x) => x.text());
  }
}
